if (localStorage.getItem('files') === null)
  localStorage.setItem('files', '[]')

export default {
  namespaced: true,
  state: {
    files: JSON.parse(localStorage.getItem('files'))
  },
  mutations: {
    add(state, { filename, content, description }) {
      // create new name, if name already exists
      if (state.files.filter(item => item.filename === filename).length > 0)
        filename = `${(new Date()).getTime()}-${filename}`

      state.files.push({ filename, content, description: description || '' })
      this.commit('storage/save')
    },
    update(state, { filename, data }) {
      let file = this.getters['storage/file'](filename)
      let index = state.files.indexOf(file)

      if (~index) {
        state.files[index] = {
          filename: data.filename,
          content: data.content,
          description: data.description || '',
        }
      }

      this.commit('storage/save')
    },
    remove(state, filename) {
      let file = this.getters['storage/file'](filename)
      let index = state.files.indexOf(file)

      if (~index)
        state.files.splice(index, 1)
      
      this.commit('storage/save')
    },
    save(state) {
      localStorage.setItem('files', JSON.stringify(state.files))
    }
  },
  getters: {
    file: (state) => (filename) => {
      const file = state.files.filter(item => item.filename === filename)
      return file.length > 0 ? file[0] : null
    },
    filenames(state) {
      const filenames = []

      for (const file of state.files) {
        const { filename, description } = file
        filenames.push({ filename, description })
      }

      return filenames
    }
  }
}
