<template lang="pug">
.pb-12.font-serif.font-light.antialiased
  main.container.mx-auto.min-h-screen.px-4
    router-view
  .fixed.bottom-0.left-0.w-full.border-t.leading-7(class="dark:bg-gray-800 dark:border-gray-900")
    .container.mx-auto.px-4
      .flex.flex-wrap.justify-evenly
        router-link.inline-flex.p-3(class="dark:text-gray-500" to="/" exact-active-class="text-purple-500 dark:text-purple-500")
          span.mr-2
            svg(viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round")
              path(d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z")
              polyline(points="14 2 14 8 20 8")
              line(x1="16" y1="13" x2="8" y2="13")
              line(x1="16" y1="17" x2="8" y2="17")
              polyline(points="10 9 9 9 8 9")
          span Files
        router-link.inline-flex.p-3(class="dark:text-gray-500" to="/notes" exact-active-class="text-purple-500 dark:text-purple-500")
          span.mr-2
            svg(viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round")
              path(d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z")
              polyline(points="14 2 14 8 20 8")
              line(x1="16" y1="13" x2="8" y2="13")
              line(x1="16" y1="17" x2="8" y2="17")
              polyline(points="10 9 9 9 8 9")
          span Notes
  footer.border-t.py-3.text-center(class="dark:border-gray-800")
    .container.mx-auto.px-4
      nav
        a.block.p-3(role="button" @click="$store.commit('theme/toggle')" :class="theme == 'dark' ? 'text-purple-500' : 'dark:text-gray-500'")
          svg(viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round")
            path(d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z")
</template>

<script>
export default {
  beforeCreate() {
    this.$store.commit('theme/apply')
  },
  computed: {
    theme() {
      return this.$store.getters['theme/value']
    }
  }
}
</script>
