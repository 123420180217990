<template lang="pug">
.my-6
  .flex.flex-wrap.max-w-md.mx-auto
    .mb-2.w-full(v-show="errorMessage.length > 0")
      p.bg-red-500.py-2.px-4.rounded-lg.text-red-100 {{ errorMessage }}
    .flex-none.mr-2
      label.inline-flex.py-2.px-4.rounded-lg.cursor-pointer(class="dark:bg-gray-800" :class="isFileLoaded ? 'text-purple-500' : ''")
        span.block.mr-2
          svg(viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round")
            path(d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z")
            polyline(points="14 2 14 8 20 8")
            line(x1="12" y1="18" x2="12" y2="12")
            line(x1="9" y1="15" x2="15" y2="15")
        span.block Open file
        input.hidden(type="file" @change="setFileName" ref="inputFile")
    .flex-auto
      input(type="text" placeholder="Filename..." class="w-full px-4 py-2 rounded-lg focus:outline-none dark:bg-gray-800 dark:placeholder-gray-700" v-model="form.filename")
    .mt-2.w-full
      textarea(placeholder="Description..." class="w-full px-4 py-2 rounded-lg focus:outline-none dark:bg-gray-800 dark:placeholder-gray-700" v-model="form.description")
    .mt-2.text-center.w-full
      button(class="bg-purple-500 hover:bg-purple-700 text-white py-2 px-4 rounded leading-tight" @click="addFile()") Upload
  hr.my-6(class="dark:border-gray-800")
  ul
    li.mb-2(v-for="file in filenames")
      .inline-flex
        span.mr-2
          svg(viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round")
            path(d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z")
            polyline(points="14 2 14 8 20 8")
            line(x1="16" y1="13" x2="8" y2="13")
            line(x1="16" y1="17" x2="8" y2="17")
            polyline(points="10 9 9 9 8 9")
        span.mr-2 {{ file.filename }}
        router-link.inline-flex.items-center(class="bg-purple-500 hover:bg-purple-700 text-white px-2 rounded" :to="`/usercustom/${file.filename}`")
          span.mr-1
            svg(viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round")
              path(d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7")
              path(d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z")
          span edit
      .text-gray-500.ml-7 {{ file.description }}
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      form: {
        filename: '',
        description: ''
      },
      errorMessage: '',
      isFileLoaded: false
    }
  },
  computed: {
    filenames() {
      return this.$store.getters['storage/filenames']
    },
  },
  methods: {
    setFileName({ target }) {
      this.isFileLoaded = true
      const file = target.files[0]
      this.form.filename = file.name
    },
    addFile() {
      const { filename, description } = this.form
      this.errorMessage = ''

      if (this.$refs.inputFile.files.length < 1) {
        this.errorMessage = 'Please select a file first.'
        return
      }

      if (this.form.filename.length < 1) {
        this.errorMessage = 'Filename can not be empty.'
        return
      }

      if (this.$store.getters['storage/file'](this.form.filename) !== null) {
        this.errorMessage = `File with the name ${filename} already exists, Please use another name.`
        return
      }

      const file = this.$refs.inputFile.files[0]
      const reader = new FileReader
      const store = this.$store

      reader.onload = (evt) => {
        let content = evt.target.result
        store.commit('storage/add', { filename, description, content })
      }
      //reader.onerror = () => state.isError = true
      reader.readAsText(file, "UTF-8")
      this.$refs.inputFile.value = ''
      this.form.filename = ''
      this.form.description = ''
      this.isFileLoaded = false
    }
  }
}
</script>
