const usercustom = {
  intval(mixedVar, base) {
    let tmp, match
    const type = typeof mixedVar

    if (type === 'boolean') {
      return +mixedVar
    } else if (type === 'string') {
      if (base === 0) {
        match = mixedVar.match(/^\s*0(x?)/i)
        base = match ? (match[1] ? 16 : 8) : 10
      }
      tmp = parseInt(mixedVar, base || 10)
      return isNaN(tmp) || !isFinite(tmp) ? 0 : tmp
    } else if (type === 'number' && isFinite(mixedVar)) {
      return mixedVar < 0 ? Math.ceil(mixedVar) : Math.floor(mixedVar)
    } else {
      return 0
    }
  },
  ord(str) {
    return str.charCodeAt(0)
  },
  chr(int) {
    return String.fromCharCode(int)
  },
  dechex(number) {
    // eslint-disable-next-line
    if (number < 0) number = 0xffffffff + number + 1
    return parseInt(number, 10).toString(16)
  },
  strSplit(str, splitLength) {
    if (splitLength === null) splitLength = 1
    if (str === null || splitLength < 1) return false
    str += ''
    const chunks = []
    let pos = 0
    const len = str.length

    while (pos < len) chunks.push(str.slice(pos, (pos += splitLength)))

    return chunks
  },
  decryptChar(char) {
    return this.chr(this.intval('01111001', 2) ^ this.intval(char, 16))
  },
  encryptChar(char) {
    let encrypted = this.dechex(this.intval('01111001', 2) ^ this.ord(char))
    encrypted = encrypted.toUpperCase()
    if (encrypted.length < 2) encrypted = `0${encrypted}`
    return encrypted
  },
  encrypt(str) {
    const chars = this.strSplit(str, 1)
    let encrypted = ''
    for (const char of chars) encrypted += this.encryptChar(char)
    return encrypted
  },
  decrypt(str) {
    const chars = this.strSplit(str, 2)
    let decrypted = ''
    for (const char of chars) decrypted += this.decryptChar(char)
    return decrypted
  }
}


export default {
  install(app) {
    app.config.globalProperties.$usercustom = usercustom
  }
}
