function setTheme(theme) {
  localStorage.setItem('theme', theme)
  document.body.classList.add('text-gray-600', 'dark:bg-gray-900', 'dark:text-gray-400')
  if (theme == 'dark') {
    document.documentElement.classList.remove('light')
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
    document.documentElement.classList.add('light')
  }
}

export default {
  namespaced: true,
  state() {
    return {
      value: localStorage.getItem('theme') || 'dark'
    }
  },
  mutations: {
    toggle(state) {
      state.value = state.value == 'dark' ? 'light' : 'dark'
      setTheme(state.value)
    },
    apply(state) {
      setTheme(state.value)
    }
  },
  getters: {
    value(state) {
      return state.value
    }
  }
}
