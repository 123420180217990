import { createStore } from 'vuex'
import theme from './theme.js'
import storage from './storage.js'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    theme,
    storage
  }
})
