import { nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'File list'
    }
  },
  {
    path: '/usercustom/:filename',
    name: 'usercustom-edit',
    component: () => import('@/views/usercustom/_filename.vue'),
    meta: {
      title: 'Edit Usercutom'
    }
  },
  {
    path: '/notes',
    name: 'notes',
    component: () => import('@/views/notes.vue'),
    meta: {
      title: 'Notes'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  nextTick(() => {
    document.title = to.meta.title || 'PUBG Config Editor';
  });
});

export default router
